import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { IsEditMessageTypes } from '../../../types/channels';

import type { PayloadAction } from '@reduxjs/toolkit';

interface FilterState {
  category: string | null;
  hasBannerSellingPrice: undefined | false;
  hasBannerBuyingPrice: undefined | false;
  hasPostBuyingPrice: undefined | false;
  hasPostSellingPrice: undefined | false;
  isMandatoryApproval: boolean | null;
  isOpenChannelsFilterModal: boolean;
  searchStr: string;
  isEditMessage: IsEditMessageTypes;
}

const initialState: FilterState = {
  category: 'ALL',
  isMandatoryApproval: null,
  hasBannerBuyingPrice: undefined,
  hasBannerSellingPrice: undefined,
  hasPostBuyingPrice: undefined,
  hasPostSellingPrice: undefined,
  isOpenChannelsFilterModal: false,
  searchStr: '',
  isEditMessage: 'ALL',
};

export const channelsFilterSlice = createSlice({
  name: 'channels-filter',
  initialState,
  reducers: {
    setHasBannerSellingPriceChannelsFilter(state, action: PayloadAction<undefined | false>) {
      state.hasBannerSellingPrice = action.payload;
    },
    setHasBannerBuyingPriceChannelsFilter(state, action: PayloadAction<undefined | false>) {
      state.hasBannerBuyingPrice = action.payload;
    },
    setHasPostBuyingPriceChannelsFilter(state, action: PayloadAction<undefined | false>) {
      state.hasPostBuyingPrice = action.payload;
    },
    setHasPostSellingPriceChannelsFilter(state, action: PayloadAction<undefined | false>) {
      state.hasPostSellingPrice = action.payload;
    },
    setSearchStrChannelsFilter(state, action: PayloadAction<string>) {
      state.searchStr = action.payload;
    },
    setIsMandatoryApprovalChannelsFilter(state, action: PayloadAction<boolean | null>) {
      state.isMandatoryApproval = action.payload;
    },
    setCategoryFilterChannelsFilter(state, action: PayloadAction<string | null>) {
      state.category = action.payload;
    },
    setIsOpenChannelsFilterModal(state, action: PayloadAction<boolean>) {
      state.isOpenChannelsFilterModal = action.payload;
    },
    setIsEditMessageChannelsFilterModal(state, action: PayloadAction<IsEditMessageTypes>) {
      state.isEditMessage = action.payload;
    },
  },
});

export const channelsFilterActions = channelsFilterSlice.actions;

export const selectChannelsFilter = (state: RootState) => state.channelsFilter;

export default channelsFilterSlice.reducer;
