import { createBrowserRouter } from 'react-router-dom';
import React from 'react';

import {
  ROUTE_CHANNELS,
  ROUTE_CREATE_ORDER,
  ROUTE_DICTIONARY,
  ROUTE_GUEST,
  ROUTE_MAIN,
  ROUTE_MARGIN_REPORT,
  ROUTE_ORDERS,
  ROUTE_ORDER_INFO,
  ROUTE_PAYMENT_CONFIRMATION,
  ROUTE_PRICES,
  ROUTE_PRICE_CALCULATOR,
  ROUTE_REPORT_ACCOUNTING,
  ROUTE_ROLE_MANAGEMENT,
  ROUTE_STATS_CLIENT,
} from './constants';

import { MobileLayout } from '../components/layouts/mobile';
import { DesktopLayout } from '../components/layouts/desktop';
import { GuardLayout } from '../components/layouts/guard';
import PersonalAreaGuard from '../components/layouts/personal-area-guard';
import { NotLocale } from '../components/layouts/not-locale';

const MainPage = React.lazy(() => import('../pages/main'));
const CreateOrderPage = React.lazy(() => import('../pages/create-order'));
const OrdersPage = React.lazy(() => import('../pages/orders'));
const OrderInfoPage = React.lazy(() => import('../pages/order-info'));
const StatsForClientPage = React.lazy(() => import('../pages/stats-client'));
const ChannelsPage = React.lazy(() => import('../pages/channels'));
const MarginReportPage = React.lazy(() => import('../pages/margin-report'));
const PaymentConfirmationsPage = React.lazy(() => import('../pages/payment-confirmations'));
const ReportAccountingPage = React.lazy(() => import('../pages/report-accounting'));
const DictionaryPage = React.lazy(() => import('../pages/dictionary'));
const PriceCalcPage = React.lazy(() => import('../pages/price-calc'));
const PricesPage = React.lazy(() => import('../pages/prices'));
const RoleManagementPage = React.lazy(() => import('../pages/role-management'));
const GuestPage = React.lazy(() => import('../pages/guest-page'));

export const router = createBrowserRouter([
  {
    path: ROUTE_GUEST,
    element: (
      <GuardLayout>
        <GuestPage />
      </GuardLayout>
    ),
  },
  {
    path: ROUTE_MAIN,
    element: (
      <GuardLayout>
        <MobileLayout>
          <NotLocale>
            <MainPage />
          </NotLocale>
        </MobileLayout>
      </GuardLayout>
    ),
  },
  {
    path: ROUTE_CREATE_ORDER,
    element: (
      <GuardLayout>
        <MobileLayout>
          <NotLocale>
            <CreateOrderPage />
          </NotLocale>
        </MobileLayout>
      </GuardLayout>
    ),
  },
  {
    path: ROUTE_ORDERS,
    element: (
      <GuardLayout>
        <MobileLayout>
          <NotLocale>
            <OrdersPage />
          </NotLocale>
        </MobileLayout>
      </GuardLayout>
    ),
  },
  {
    path: ROUTE_ORDER_INFO,
    element: (
      <GuardLayout>
        <MobileLayout>
          <NotLocale>
            <OrderInfoPage />
          </NotLocale>
        </MobileLayout>
      </GuardLayout>
    ),
  },
  {
    path: ROUTE_CHANNELS,
    element: (
      <GuardLayout>
        <MobileLayout>
          <NotLocale>
            <ChannelsPage />
          </NotLocale>
        </MobileLayout>
      </GuardLayout>
    ),
  },
  {
    path: ROUTE_STATS_CLIENT,
    element: <StatsForClientPage />,
  },
  {
    path: ROUTE_PRICE_CALCULATOR,
    element: (
      <GuardLayout>
        <MobileLayout>
          <NotLocale>
            <PriceCalcPage />
          </NotLocale>
        </MobileLayout>
      </GuardLayout>
    ),
  },
  {
    path: ROUTE_PRICES,
    element: (
      <DesktopLayout>
        <PersonalAreaGuard>
          <NotLocale>
            <PricesPage />
          </NotLocale>
        </PersonalAreaGuard>
      </DesktopLayout>
    ),
  },
  {
    path: ROUTE_ROLE_MANAGEMENT,
    element: (
      <DesktopLayout>
        <PersonalAreaGuard>
          <NotLocale>
            <RoleManagementPage />
          </NotLocale>
        </PersonalAreaGuard>
      </DesktopLayout>
    ),
  },
  {
    path: ROUTE_MARGIN_REPORT,
    element: (
      <DesktopLayout>
        <PersonalAreaGuard>
          <NotLocale>
            <MarginReportPage />
          </NotLocale>
        </PersonalAreaGuard>
      </DesktopLayout>
    ),
  },
  {
    path: ROUTE_REPORT_ACCOUNTING,
    element: (
      <DesktopLayout>
        <PersonalAreaGuard>
          <NotLocale>
            <ReportAccountingPage />
          </NotLocale>
        </PersonalAreaGuard>
      </DesktopLayout>
    ),
  },
  {
    path: ROUTE_PAYMENT_CONFIRMATION,
    element: (
      <DesktopLayout>
        <PersonalAreaGuard>
          <NotLocale>
            <PaymentConfirmationsPage />
          </NotLocale>
        </PersonalAreaGuard>
      </DesktopLayout>
    ),
  },
  {
    path: ROUTE_DICTIONARY,
    element: (
      <GuardLayout>
        <MobileLayout>
          <NotLocale>
            <DictionaryPage />
          </NotLocale>
        </MobileLayout>
      </GuardLayout>
    ),
  },
]);
